import React from 'react';
import { Link, graphql, navigate, StaticQuery } from 'gatsby';
import BackgroundImage from 'gatsby-background-image';
import DefaultLayoutComponent from '../modules/layouts/DefaultLayoutComponent';
import BodyClassName from 'react-body-classname';
import NotFound404Page from './404';

const ThankYouPage = (props) => {
  const {
    data: { bgThankYou },
  } = props;

  const isOrderSuccess = props?.location?.state?.orderSuccess;
  const isDonationSuccess = props?.location?.state?.donationSuccess;

  if (!(isOrderSuccess || isDonationSuccess)) return <NotFound404Page data={props.data} />;

  const successText = isDonationSuccess ? 'donation' : 'order';

  return (
    <BodyClassName className="body-dark page-thank-you">
      <DefaultLayoutComponent title="Thanks">
        <div className="site-main">
          <div className="wrapper wrapper-xl pd-x-md">
            <BackgroundImage className="section bg-light thank-you bgImage" fluid={bgThankYou.childImageSharp.fluid}>
              <div className="overlay" />
              <div className="wrapper pd-x-md">
                <h2>Thanks for your {successText}!</h2>
                <p>
                  Your {successText} has been successfully processed. We have sent information about the {successText}{' '}
                  to your email.
                </p>
                <Link to={isDonationSuccess ? '/' : '/shop/'} className="btn btn-md btn-primary-ii back-to-shop">
                  Back To {isDonationSuccess ? 'Home' : 'Shop'}
                </Link>
              </div>
            </BackgroundImage>
          </div>
        </div>
      </DefaultLayoutComponent>
    </BodyClassName>
  );
};

export const query = graphql`
  {
    bgThankYou: file(relativePath: { eq: "bg-thank-you.png" }) {
      childImageSharp {
        fluid(quality: 100, maxWidth: 1280) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }

    bgContact: file(relativePath: { eq: "bg-contact.jpg" }) {
      childImageSharp {
        fluid(quality: 100, maxWidth: 1280) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
  }
`;

export default ThankYouPage;
